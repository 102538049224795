<template>
  <div>
    <div class="div-form">
      <div class="div-title">
        <div class="text-center" v-if="success">
          <i class="icon-success fa-light fa-circle-check"></i><br />
          <div class="title"> {{ title }} confirmado com sucesso!</div>
          <div class="div-login" @click="navegateToLogin">Efetuar login</div>
        </div>
        <div class="text-center" v-if="error">
          <i class="icon-error fa-solid fa-circle-xmark"></i><br />
          <div class="title"> Solicitação expirada!</div>
        </div>
        <Loading :center="false" v-show="loading" />
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import { mapActions } from "vuex";

export default {
  name: "ConfirmMailMobile",
  components: { Loading },
  data() {
    return {
      urlReset: "api/v1/adm/auth/confirm-mail-mobile",
      loading: true,
      success: false,
      error: false,
      route: this.$route.path,
    };
  },
  created() {
    this.confirm();
  },
  computed: {
    title() {
      return this.route.includes("mobile") ? "WhatsApp" : "E-mail";
    },
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    confirm() {
      let obj = {
        codeConfirmedEmailMobile: this.$route.params.code,
        typeConfirm: this.route.includes("mobile") ? "mobile" : "email",
      };
      let params = { url: this.urlReset, obj: obj };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.success = true;
        } else {
          this.error = true;
        }
        this.loading = false;
      });
    },
    navegateToLogin() {
      this.$router.replace(this.$route.query.redirect || "/login");
    },
  },
};
</script>
<style scoped>
.div-form {
  padding-top: 60px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 60px;
}

.div-login {
  font-size: 15px;
  margin-top: 18px;
  margin-bottom: 5px;
  cursor: pointer;
  color: #4680a5;
}

.div-logo {
  margin-top: 60px;
}

.title {
  font-size: 18px;
  margin: 10px;
}

.icon-success {
  color: #38A169;
  font-size: 50px;
}

.icon-error {
  color: red;
  font-size: 50px;
}
</style>
